@import url(../../assets/styles/_global.scss);

.navbarContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 20px 15px;
  color: #344767;
  background-color: white;
  margin-inline-start: 250px;
  // overflow: hidden;

  & > div:first-of-type {
    display: flex;
    align-items: center;
    gap: 10px;
    div {
      display: flex;
      align-self: center;
      justify-content: center;
      border-radius: 4px;
      border: 1px solid #17171742;
      width: 30px;
      padding: 4px 4px;
    }
  }
  .pendingStatus {
    background-color: #ffd79d;
    border-radius: 30px;
    font-size: 13px;
    padding: 5px 14px;
  }
  .approvedStatus {
    background-color: #aee9d1;
    border-radius: 30px;
    font-size: 13px;
    padding: 5px 14px;
  }
  .acceptedStatus {
    background-color: #16268d;
    color: white;
    border-radius: 30px;
    font-size: 13px;
    padding: 5px 14px;
  }
  .cancelledStatus {
    background-color: #e91f63;
    color: white;
    border-radius: 30px;
    font-size: 13px;
    padding: 5px 14px;
  }
  svg {
    vertical-align: middle;
    cursor: pointer;
  }
  .prof-header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .profile {
    display: flex;
    justify-content: center;
    align-items: center;
    .logouticon {
      margin-left: 7px;
      margin-right: 7px;
    }
    .userName {
      color: #202223;
    }
  }

  .dropdown-content {
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
    position: absolute;
    top: 85px;
    right: 20px;
    background-color: #344767;

    box-shadow: 0px 8px 16px 0px rgba(193, 143, 143, 0.2);
    z-index: 5;
    padding: 12px 24px;
  }

  .dropdown-content button {
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding: 12px 24px;
    cursor: pointer;
    background: #344767;
    border: 1px solid #babfc3;
    color: #f8f9fa;
    svg {
      vertical-align: middle;
    }
  }

  .dropdown-content a:hover {
    background-color: #ddd;
  }

  .show {
    display: block;
  }
}
