@import url(../../assets/styles/_global.scss);

.forgetPasswordContainer {
  background: url("../../../public/loginBg.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  .container {
    min-width: 426px;
    max-width: 90%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    justify-content: center;
    gap: 30px;
    .logo {
      display: flex;
      align-items: center;
      gap: 10px;
      span {
        font-family: "Metropolis";
        letter-spacing: 2px;
        font-size: 21px;
      }
      img {
        filter: drop-shadow(0px 10px 5px #0000008b);
        width: 35px;
      }
    }

    & > div:nth-of-type(2) {
      h3,
      h5 {
        text-align: left;
      }
      h3 {
        font-weight: 700;
        font-size: 22px;
        line-height: 140%;
        color: #344767;
      }
      h5 {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color: #344767;
        width: 80%;
      }
    }

    .formContainer {
      width: 100%;
      form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        .inputBoxContainer {
          width: 100%;
          margin: 0;
        }
        input {
          width: 100%;
        }
        span {
          width: 100%;
          text-align: right;
          font-weight: 400;
          font-size: 12px;
          line-height: 140%;
          a {
            color: #344767;
            text-decoration: none;
          }
        }
        button {
          width: 100%;
          background: #344767;
          border-radius: 8px;
          border: none;
          outline: none;
          padding: 10px 0;
          color: white;
          cursor: pointer;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 140%;
          text-align: center;
        }
        & > div:last-of-type {
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          color: #344767;
          a {
            text-decoration: none;
            color: #344767;
            font-weight: 700;
          }
        }
      }
    }
    .navigation {
      margin-top: 30px;
      a {
        display: flex;
        align-items: center;
        gap: 10px;
        color: #344767;
        cursor: pointer;
        svg {
          vertical-align: middle;
        }
      }
    }
  }
}
