@import url(../../assets/styles/_global.scss);

$activeColor: #27ae60; //green
$darkenColor: darken($activeColor, 20%);
/* $background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/13460/dark_wall.png'); */
$background: #3498db;

.tagSelectorContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: #000000, $alpha: 0.25);
  .container {
    background-color: rgba($color: #344767, $alpha: 0.9);
    padding: 30px 10px;
    width: 400px;
    min-height: fit-content;
    max-height: 40vh;
    border-radius: 12px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    overflow-y: auto;

    .header {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      svg {
        vertical-align: middle;
        text-align: right;
        fill: white;
        stroke: white;
        cursor: pointer;
        path {
          stroke: white;
          fill: white;
        }
      }
      h2 {
        text-align: center;
        color: white;
        font-weight: 700;
        font-size: 24px;
        line-height: 2rem;
      }
    }
    hr {
      margin-block: 10px;
    }
    /* .roundedOne */
    .greenInput {
      display: flex;
      align-items: center;
      gap: 10px;
      .label {
        outline: none;
        padding: 3px 5px;
        color: white;
        line-height: 2rem;
      }
    }
    .roundedOneI {
      height: fit-content;
      display: flex;
      align-items: center;
    }
    .roundedOne {
      width: 28px;
      height: 28px;
      position: relative;
      margin: 10px auto;
      background: #fcfff4;
      background: linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
      border-radius: 50px;
      box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);

      label {
        width: 20px;
        height: 20px;
        cursor: pointer;
        position: absolute;
        left: 4px;
        top: 4px;
        background: linear-gradient(top, #222 0%, #45484d 100%);
        border-radius: 50px;
        box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5),
          0px 1px 0px rgba(255, 255, 255, 1);
        &:after {
          content: "";
          width: 16px;
          height: 16px;
          position: absolute;
          top: 1px;
          left: 2px;
          background: $activeColor;
          background: linear-gradient(top, $activeColor 0%, $darkenColor 100%);
          opacity: 0;
          border-radius: 50px;
          box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
        }
        &:hover::after {
          opacity: 0.3;
        }
      }
      input[type="checkbox"] {
        visibility: hidden;
        &:checked + label:after {
          opacity: 1;
        }
      }
    }
    /* end .roundedOne */
  }
}
