.upload {
  &__image-wrapper {
    position: relative;
  }

  &__image-dropzone {
    all: unset;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    border: 1px dashed #1f5199;
    border-radius: 8px;
    padding: 1rem 1rem;
    margin-bottom: 1rem;
    font-size: 0.85rem;
    cursor: pointer;
  }
  &--error {
    &__image-dropzone {
      border-color: #d32f2f;
    }
  }

  &__image-dropzone-btn {
    display: inline-block;
    background-color: #e5effd;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    color: #1f5199;
    text-transform:capitalize !important;
  }

  &__image-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 1rem;
  }
}

.image-item {
  position: relative;
  width: 100%;
  border: 2px solid #e5effd;
  border-radius: 5px;
  padding: 10px;

  img {
    display: block;
    width: 100%;
    height: 140px;
    border-radius: 8px;
    overflow: hidden;
    object-fit: cover;
    cursor: pointer;
  }

  &__btn-remove {
    position: relative;
    top: 0;
    cursor: pointer;
  }
}
