.countryCode {

  align-items: center !important;

  .PhoneInputInput {
    border: none;
  }
  .PhoneInputInput:focus,
  .PhoneInputInput:focus-visible {
    outline: none;
  }
}
.countryCode.PhoneInput{
   border-radius: 12px;
}
