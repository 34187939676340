 @import url(../../assets/styles/_global.scss);

.confirm-delete {
  .MuiPaper-root {
    border-radius: 8px;
    width: 50%;
  }
}

.dialogActions {
  display: flex;
  justify-content: space-between !important;
  margin: 0px !important;
  padding: 0px !important;
  gap: 30px;
}
.ButtonDialog.ButtonDialog {
  margin: 15px;
}
.PaperProps {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center;
  margin-bottom: 5rem;
}
.dialogTitle {
  color: var(--Color-Basics-black---light, #000716);
  font-size: 28px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: center;
}
.dialogContent {
  color: var(--Color-Gray-grey-900, #6c6c6c);
  font-size: 20px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
  padding-top: 5px;
  letter-spacing: 0em;
  text-align: center;
}
@media only screen and (max-width: 599px) {
  .confirm-delete {
    overflow: hidden !important;
    overflow-x: hidden !important;
    .MuiButtonBase-root {
      width: 100px !important;
      height: 30px;
      padding: 0px;
    }
    .MuiIconButton-root.MuiButtonBase-root {
      width: auto !important;
    }
  }
  .dialogTitle {
    font-size: 22px !important;
    line-height: 20px;
  }
  .dialogContent {
    font-size: 18px !important;
    font-weight: 700;
    line-height: 10px;
    padding-top: 0px;
  }
  .PaperProps {
    margin-bottom: 3rem;
  }
}
