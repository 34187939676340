@import url(../../assets/styles/_global.scss);

.floatingFormContainer {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.25);
  display: flex;
  justify-content: flex-end;
  overflow-y: hidden;

  .container {
    width: 50%;
    background-color: white;
    padding: 20px 33px;
    overflow-y: auto;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 700;
      font-size: 24px;
      line-height: 140%;
      color: #344767;
      margin-bottom: 1.6rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
      // svg {
      //   vertical-align: middle;
      //   cursor: pointer;
      // }
    }
    // hr {
    //   margin-top: 20px;
    //   border-color: rgba($color: #000000, $alpha: 0.1);
    //   border-width: 1px;
    //   border-style: solid;
    // }
    // .flex {
    //   display: flex;
    //   justify-content: space-between;
    //   align-items: center;
    // }
    // label {
    //   display: block;
    //   font-weight: 700;
    //   font-size: 12px;
    // }
    // textarea {
    //   margin-top: 10px;
    //   padding: 12px 16px;
    //   outline: none;
    //   border: 1px solid #c5c6cc;
    //   border-radius: 8px;
    //   width: 300px;
    // }
    // .actionsContainer {
    //   display: flex;
    //   justify-content: flex-end;
    //   gap: 10px;
    //   width: 100%;
    //   margin-top: 30px;
    //   button {
    //     padding: 8px 24px;
    //     background: #ffffff;
    //     border: 1px solid #babfc3;
    //     box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    //     border-radius: 4px;
    //     cursor: pointer;
    //   }
    //   & > button:last-of-type {
    //     padding: 8px 24px;
    //     background: #344767;
    //     border: 1px solid #babfc3;
    //     box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    //     border-radius: 4px;
    //     color: white;
    //   }
    // }
  }

  .floatingFormCloseButton {
    all: unset;
    cursor: pointer;
  }
}
