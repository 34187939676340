@import url(../../assets/styles/_global.scss);

.selectBoxContainer {
  position: relative;
  margin: 10px 30px 10px 0;
  width: fit-content;
  label {
    display: block;
    font-weight: 700;
    font-size: 12px;
    line-height: 2rem;
  }
  select {
    width: 300px;
    padding: 12px 16px;
    border: 1px solid #c5c6cc;
    background-color: transparent;
    outline: none;
    color: #8f9098;
    font-weight: bold;
    border-radius: 8px;
    text-align-last: left;
    -webkit-appearance: none;
    line-height: 1rem;
  }
  svg {
    position: absolute;
    right: 0.9375rem;
    top: 65%;
  }
}
