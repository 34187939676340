.label.MuiFormLabel-root {
  color: #2f3036;
  margin-top: 5px;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.52px;
  text-align: left;
  margin: 5px 0;
}

.textField {
  .MuiInputBase-root {
    border-radius: 12px;
    border-color: #d6ddeb;
    width: Fill (317.33px) px;
    // padding: 12px 16px 12px 16px;
    gap: 8px;
    // opacity: 0px;
  }
}
.MuiFormControl-root {
  margin: 0px !important;
}
input::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #8f9098;
}
textarea::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #8f9098;
}
