@import url(../../assets/styles/_global.scss);

.preferencesContainer {
  .contentEmpty {
    width: calc(100% - 280px);
    margin: 0 15px 30px auto;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content {
    .container {
      padding: 10px 20px;
      background-color: white;
    }

    .header {
      background-color: #eeeded;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      border-radius: 4px;
      & > div:first-of-type {
        display: flex;
        align-items: center;
        gap: 10px;
      }
      & > div:last-of-type {
        display: flex;
        align-items: center;
        gap: 10px;

        font-family: "SF Pro";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #000000;

        .switch {
          position: relative;
          display: inline-block;
          width: 60px;
          height: 34px;
        }

        /* Hide default HTML checkbox */
        .switch input {
          opacity: 0;
          width: 0;
          height: 0;
        }

        /* The slider */
        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #ccc;
          -webkit-transition: 0.4s;
          transition: 0.4s;
        }

        .slider:before {
          position: absolute;
          content: "";
          height: 26px;
          width: 26px;
          left: 4px;
          bottom: 4px;
          background-color: white;
          -webkit-transition: 0.4s;
          transition: 0.4s;
        }

        input:checked + .slider {
          background-color: #2196f3;
        }

        input:focus + .slider {
          box-shadow: 0 0 1px #2196f3;
        }

        input:checked + .slider:before {
          -webkit-transform: translateX(26px);
          -ms-transform: translateX(26px);
          transform: translateX(26px);
        }

        /* Rounded sliders */
        .slider.round {
          border-radius: 34px;
        }

        .slider.round:before {
          border-radius: 50%;
        }
      }
      svg {
        vertical-align: middle;
      }
    }
    .title {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #202223;
    }
    .body {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #202223;
    }

    & > div:first-of-type {
      margin-top: 30px;
    }

    .container {
      height: fit-content;
      & > div:first-of-type {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        gap: 20px;
        .imgContainer {
          width: 250px;
          height: 200px;
          border-radius: 2px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 2px;
          }
        }
        & > div:last-of-type {
          width: -webkit-fill-available;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          hr {
            margin: 10px 0;
          }
          & > div:first-of-type {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
      }
      & > div:last-of-type {
        margin-top: 30px;
        .tableContainer {
          width: 100%;
          height: fit-content;
        }
      }
    }
  }
}
