@import url(../../../assets/styles/_global.scss);

.form-section {
  margin-bottom: 1.6rem;
}

.header {
  background-color: #eeeded;
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 4px 4px 0 0;
  gap: 10px;
  padding-bottom: 15px;

  h2 {
    font-size: 1rem;
  }

  svg {
    vertical-align: middle;
  }
}
.hond{
    background-color: #eeeded;
    width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h2 {
    font-size: 1rem;
  }

  svg {
    vertical-align: middle;
  }
}

.editProductAction {
  margin-inline-start: auto;
  width: calc(100% - 250px);

  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;

  svg {
    vertical-align: middle;
  }

  .routes {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #202223;

    span {
      cursor: pointer;
    }
  }
}

.content {
  margin-inline-start: auto;
  width: calc(100% - 310px);
  margin-inline-end: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;

  .body-rtl & {
    margin-inline-end: 2.3rem;
  }
}

.section-body {
  background-color: white;
  border-radius: 0 0 4px 4px;
  padding: 30px 60px;
}

.addon-item {
  border-bottom: 1px solid #e5e5e5;
  // margin-bottom: 0.3rem;

  &:last-of-type {
    border-bottom: none;
  }
}
.formButton.MuiButton-root {
  width: 101px;
  height: 44px;
  padding: 12px 24px 12px 24px;
  gap: 10px;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  border: 1px solid #babfc3;
  border-radius: 4px;
  // opacity: 0px;
  text-transform: capitalize;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #202223;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.buttonHandleSubmit.MuiButton-root {
  background-color: #344767;
  color: #f8f9fa;
  min-width: 86px;
  text-transform: capitalize;
  height: 44px;
  padding: 12px 24px 12px 24px;
  gap: 10px;
  border-radius: 4px;
  border: 1px 0px 0px 0px;
  // opacity: 0px;
  border: 1px solid #babfc3;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.buttonHandleSubmit:hover {
  background-color: #172a3f !important;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.formButton.MuiButton-root:hover {
  border: 1px solid #344767;
  background-color: #ffffff;
}
@media only screen and (min-device-width: 200px) and (max-device-width: 599px) {
  .formButton.MuiButton-root {
    width: 100%;
  }
}
