//Colors
$primaryColor: #344767;
$whiteColor: #ffffff;

//Fonts
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;600;700;900&display=swap");

@font-face {
  font-family: "Metropolis";
  src: url("../fonts/metropolis/Metropolis-Bold.otf");
  font-weight: 700;
}

@font-face {
  font-family: "Metropolis";
  src: url("../fonts/metropolis/Metropolis-ExtraBold.otf");
  font-weight: 900;
}

@font-face {
  font-family: "Metropolis";
  src: url("../fonts/metropolis/Metropolis-Regular.otf");
  font-weight: 400;
}

@font-face {
  font-family: "Metropolis";
  src: url("../fonts/metropolis/Metropolis-Medium.otf");
  font-weight: 500;
}

//Media Queries
@mixin media($breakpoint) {
  @if $breakpoint ==phone-sm {
    @media only screen and (max-width: 320px) {
      @content;
    }
  }

  @if $breakpoint ==phone-md {
    @media only screen and (max-width: 480px) {
      @content;
    }
  }

  @if $breakpoint ==phone-lg {
    @media only screen and (max-width: 576px) {
      @content;
    }
  }

  @if $breakpoint ==tablet-sm {
    @media only screen and (max-width: 798px) {
      @content;
    }
  }

  @if $breakpoint ==tablet-md {
    @media only screen and (max-width: 900px) {
      @content;
    }
  }

  @if $breakpoint ==tablet-lg {
    @media only screen and (max-width: 1024px) {
      @content;
    }
  }

  @if $breakpoint ==desktop-sm {
    @media only screen and (max-width: 1200px) {
      @content;
    }
  }

  @if $breakpoint ==desktop-md {
    @media only screen and (max-width: 1366px) {
      @content;
    }
  }

  @if $breakpoint ==desktop-lg {
    @media only screen and (max-width: 1680px) {
      @content;
    }
  }
}

// Global Styles

body {
  margin: 0;
  padding: 0;
  font-family: "Metropolis", sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f4f5f7;

  * {
    font-family: "Metropolis", sans-serif !important;
  }
  .label.MuiFormLabel-root {
    color: #2f3036;
    margin-top: 5px;
    font-size: 12px;
    font-weight: 700;
    line-height: 14.52px;
    text-align: left;
    margin: 5px 0;
  }

  .textField {
    .MuiInputBase-root {
      border-radius: 12px;
      border-color: #d6ddeb;
      width: Fill (317.33px) px;
      // padding: 12px 16px 12px 16px;
      gap: 8px;
      // opacity: 0px;
    }
  }
  .MuiFormControl-root {
    margin: 0px !important;
  }
  input::-webkit-input-placeholder {
    font-size: 14px;
    font-weight: 400;
    color: #8f9098;
  }
  textarea::-webkit-input-placeholder {
    font-size: 14px;
    font-weight: 400;
    color: #8f9098;
  }

  .formButton.MuiButton-root {
    width: 101px;
    height: 44px;
    padding: 12px 24px 12px 24px;
    gap: 10px;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    border: 1px solid #babfc3;
    border-radius: 4px;
    // opacity: 0px;
    text-transform: capitalize;
    background-color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #202223;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
  }
  .buttonHandleSubmit.MuiButton-root {
    background-color: #344767;
    color: #f8f9fa;
    min-width: 86px;
    text-transform: capitalize;
    height: 44px;
    padding: 12px 24px 12px 24px;
    gap: 10px;
    border-radius: 4px;
    border: 1px 0px 0px 0px;
    // opacity: 0px;
    border: 1px solid #babfc3;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
  }
  .buttonHandleSubmit:hover {
    background-color: #172a3f !important;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
  .formButton.MuiButton-root:hover {
    border: 1px solid #344767;
    background-color: #ffffff;
  }
  @media only screen and (min-device-width: 200px) and (max-device-width: 599px) {
    .formButton.MuiButton-root {
      width: 100%;
    }
  }
}

body.body-rtl {
  direction: rtl;
  text-align: right;
  font-family: "Cairo", sans-serif;

  * {
    font-family: "Cairo", sans-serif !important;
  }
}

*,
h2,
h3 {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

//scrollbar style
*::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

*::-webkit-scrollbar-track {
  background-color: $whiteColor;
}

*::-webkit-scrollbar-thumb {
  // background-color: $primaryColor;
  border-radius: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/********************************************************/

//Globals

//display flex
@mixin flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

/******************************/

.file-uploader-body {
  padding: 1.2rem;
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed #344767;
  border-radius: 8px;
  cursor: pointer;

  .btn {
    all: unset;
    color: #1f5199;
    border-radius: 8px;
    background-color: #e5effd;
    padding: 0.6rem 0.6rem;
    margin-bottom: 0.5rem;
  }
}

.capitalize {
  text-transform: capitalize;
}

.page-container {
  margin-inline-start: 250px;
  padding: 2rem;
}
