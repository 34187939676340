@import url(../../assets/styles/_global.scss);

.filterHeaderContainer {
  display: flex;
  justify-content: flex-end;
  background-color: white;
  align-items: center;
  padding-inline-start: 250px;
  padding-inline-end: 1rem;

  .search {
    position: relative;
    margin: 10px;
    margin-inline-start: 0;
    border: 1px solid #c5c6cc;
    padding-inline-end: 20px;
    border-radius: 8px;
    width: 400px;

    svg {
      position: absolute;
      inset-inline-end: 14px;
      top: 12px;
      vertical-align: middle;
    }

    input {
      outline: none;
      padding: 13px 16px;
      border: none;
      width: 90%;
    }
  }

  .selectBoxContainer {
    margin-right: 10px;

    select {
      padding: 12px 16px;
      width: fit-content;
      padding-right: 50px;
    }

    svg {
      top: 36%;
    }
  }

  .addContainer {
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 1px solid #babfc3;
    border-radius: 4px;
    padding: 10px !important;
    width: fit-content !important;
    gap: 10px;
    cursor: pointer;

    svg {
      vertical-align: middle;
    }
  }
}