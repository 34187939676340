@import url(../../assets/styles/_global.scss);

.loginContainer {
  background: url("../../../public/signUpBg.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  .container {
    .loginTitle {
      text-align: center;
      h3 {
        font-weight: 700;
        font-size: 22px;
        line-height: 140%;
        color: #344767;
      }
      h5 {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color: #344767;
      }
    }
    min-width: 426px;
    max-width: 90%;
    background-color: white;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    justify-content: center;
    gap: 24px;
    .logo {
      display: flex;
      align-items: center;
      gap: 10px;
      span {
        font-weight: 700;
        font-family: "Metropolis";
        letter-spacing: 2px;
        font-size: 28px;
      }
      img {
        filter: drop-shadow(0px 10px 5px #0000008b);
        width: 46px;
      }
    }

    & > div:nth-of-type(2) {
      h3,
      h5 {
        text-align: center;
      }
      h3 {
        font-weight: 700;
        font-size: 22px;
        line-height: 140%;
        color: #344767;
      }
      h5 {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color: #344767;
      }
    }
  }
}
