@import url(../../assets/styles/_global.scss);

.resetConfirmationContainer {
  background: url("../../../public/loginBg.png");
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  .container {
    min-width: 500px;
    max-width: 90%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .content {
      text-align: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      .svgContainer {
        img {
          width: 125px;
          height: 50px;
        }
      }
      h3 {
        font-weight: 700;
        font-size: 25px;
        line-height: 140%;
        color: #1a73e7;
      }
      span {
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color: #7b809a;
        a {
          text-decoration: none;
          color: #1a73e7;
        }
      }
    }
  }
}
