 @import url(../../assets/styles/_global.scss);

.no-data {
	text-align: center;

	&__graphic {
		margin-bottom: 0;
	}

	&__text {
		font-size: 20px;
		font-weight: 600;
		margin-bottom: 1rem;
	}

	&__subtext {
		font-size: 1rem;
		font-weight: 400;
		color: #4A4A4A;
	}

	&__button {
		margin-top: 1rem;
	}
}