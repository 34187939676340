@import url(../../assets/styles/_global.scss);

.error {
  box-shadow: 0px 3px 6px rgba(black, 0.1);
  overflow: hidden;
  overflow-x: auto;
  overflow-y: auto;
  width: calc(100% - 280px);
  min-height: 80vh;
  max-height: 80vh;
  background-color: #ffffff;
  margin: 30px 15px 30px auto;
  text-align: center;
}
