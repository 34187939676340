@import url(../../assets/styles/_global.scss);

.tableContainer {
  box-shadow: 0px 3px 6px rgba(black, 0.1);
  overflow: hidden;
  overflow-x: auto;
  overflow-y: auto;
  width: calc(100% - 280px);
  min-height: 70vh;
  max-height: 80vh;
  background-color: #ffffff;
  margin: 30px 15px 30px;
  margin-inline-start: auto;
  position: relative;

  &::-webkit-scrollbar {
    width: 0px;
    height: 6px;
  }
  .infinite-scroll-component {
    overflow: visible !important;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    position: relative;
    thead {
      th {
        position: sticky;
        top: 0;
        z-index: 4;
        background-color: #ffffff;
        padding: 1.25rem 0;
        font-size: 14px;
        font-weight: bold;
        color: black;
        text-transform: capitalize;
        padding-left: 50px;
        white-space: nowrap;
        text-align: left;
        &:nth-child(1) {
          padding-right: 20px;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 0.9rem 0px;
          padding-left: 50px;
          font-weight: 500;
          text-align: right;
          min-width: fit-content;
          white-space: nowrap;
          text-align: left;
          font-size: 0.8em;
        }
        border-top: 1px solid #e1e3e5;
        background-color: #ffffff;
      }
      a {
        text-decoration: none;
        color: black;
        &:hover {
          color: black;
        }
        &:active {
          color: black;
        }
      }
      svg {
        vertical-align: middle;
        cursor: pointer;
      }
      .flexActions {
        display: flex;
        width: 100%;
        gap: 0.7rem;
      }
      .flexActionsRtl {
        display: flex;
        width: 100%;
        gap: 0.7rem;
        justify-content: flex-end;
      }
      .divstatusRTl {
        display: flex;
        width: 100%;
        justify-content: flex-end;
      }

      .pendingStatus,
      .pendingStatusRtl {
        width: 90px;
        display: block;
        background-color: #ffd79d;
        border-radius: 30px;
        font-size: 13px;
        font-weight: 900;
        padding: 10px 12px;
        text-align: center;
      }
      .approvedStatus,
      .approvedStatusRtl {
        width: 90px;
        display: block;
        background-color: #aee9d1;
        border-radius: 30px;
        font-size: 13px;
        font-weight: 900;
        padding: 10px 12px;
        text-align: center;
      }
      .acceptedStatus,
      .acceptedStatusRtl {
        width: 90px;
        display: block;
        background-color: #16268d;
        color: white;
        border-radius: 30px;
        font-weight: 900;
        font-size: 13px;
        padding: 10px 12px;
        text-align: center;
      }
      .cancelledStatus,
      .cancelledStatusRTl {
        width: 90px;
        display: block;
        background-color: #e91f63;
        color: white;
        border-radius: 30px;
        font-weight: 900;
        font-size: 13px;
        padding: 10px 12px;
        text-align: center;
      }
      .cancelledStatusRTl,
      .approvedStatusRtl,
      .pendingStatusRtl,
      .acceptedStatusRtl {
        font-weight: 700;
        padding: 6px 12px !important;
      }
    }
  }
}

.reactPaginate {
  position: relative;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
  width: 100%;
  height: 100%;
  background-color: rgba(#fff, 0.8);
  padding: 10px 5px;

  .active {
    background-color: #344767;
    color: #fff !important;
  }

  .page {
    width: 50px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2em;
    font-weight: 400;
    color: #000;
    border: 1px solid #000;
    cursor: pointer;
    &:hover {
      background-color: #344767;
      color: #fff;
    }
    a {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .break {
    width: 50px;
    height: 30px;
    display: flex;
    align-items: baseline;
    justify-content: center;
    color: #000;
    cursor: pointer;
    &:hover {
      background-color: #344767;
      color: #fff;
    }
  }

  .disabled {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    font-weight: 400;
    color: red;
    cursor: pointer;
    &:hover {
      color: #344767;
    }
  }

  .previous {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    font-weight: 400;
    color: #000;
    cursor: pointer;
    &:hover {
      color: #344767;
    }
  }

  .next {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    font-weight: 400;
    color: #000;
    cursor: pointer;
    &:hover {
      color: #344767;
    }
  }
}
