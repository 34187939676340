@import url(../../assets/styles/_global.scss);

.emptyContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  button {
    width: 100%;
    background: #344767;
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 10px 25px;
    color: white;
    cursor: pointer;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 140%;
    text-align: center;
  }
}
