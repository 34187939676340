@import url(../../assets/styles/_global.scss);

.signUpContainer {
  background: url("../../../public/signUpBg.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .container {
    min-width: 810px;
    max-width: 90%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 24px;
    padding: 24px;
    justify-content: center;
    gap: 30px;
    .logo {
      display: flex;
      align-items: center;
      gap: 10px;
      span {
        font-family: "Metropolis";
        letter-spacing: 2px;
        font-size: 21px;
      }
      img {
        filter: drop-shadow(0px 10px 5px #0000008b);
        width: 35px;
      }
    }

    & > div:nth-of-type(2) {
      h3,
      h5 {
        text-align: center;
      }
      h3 {
        font-weight: 700;
        font-size: 22px;
        line-height: 140%;
        color: #344767;
      }
      h5 {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color: #344767;
      }
    }

    .formContainer {
      width: 100%;
      form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        & > div:first-of-type {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          div {
            display: flex;
            width: 100%;
            gap: 10px;
            margin: 10px 0;
            .inputBoxContainer {
              width: 100%;
              margin: 0;
            }
            input {
              width: 100%;
            }
          }
          span {
            width: 100%;
            text-align: right;
            font-weight: 400;
            font-size: 12px;
            line-height: 140%;
            a {
              color: #344767;
              text-decoration: none;
            }
          }
        }
        button {
          width: 40%;
          background: #344767;
          border-radius: 8px;
          border: none;
          outline: none;
          padding: 10px 0;
          color: white;
          cursor: pointer;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 140%;
          text-align: center;
        }
        & > div:last-of-type {
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          color: #344767;
          a {
            text-decoration: none;
            color: #344767;
            font-weight: 700;
          }
        }
        .PhoneInput {
          margin: 0px !important;
          input {
            padding: 12px 16px;
            outline: none;
            border: 1px solid #c5c6cc;
            border-radius: 8px;
            line-height: 1rem;
          }

          .PhoneInputCountry {
            width: 7% !important;
            background-color: transparent;

            .PhoneInputCountrySelectArrow {
              width: 0.3em !important;
              height: 0.3em !important;
              display: block !important;
              margin-left: -20px;
            }
            .PhoneInputCountryIcon--border {
              width: 1em;
              height: fit-content;
              margin: 0;
              margin-right: 0.9em !important;
            }
          }
        }
      }
    }
  }
}
