@import url(../../assets/styles/_global.scss);

.menubarContainer {
  background-color: #344767;
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: fixed;
  gap: 20px;
  // top: 0;
  // left: 0;
  // bottom: 0;
  inset-block: 0;
  inset-inline-start: 0;
  inset-inline-end: auto;
  color: #f8f9fa;
  padding: 10px;
  overflow-x: hidden;

  & > div:first-of-type {
    display: flex;
    align-items: center;
    gap: 10px;
    span {
      font-family: "Metropolis";
      font-weight: 700;
      letter-spacing: 5px;
      font-size: 32px;
    }
    img {
      width: 50px;
      height: 45px;
    }
  }
  .menuContainer {
    margin-top: 20px;
    width: inherit;
    svg {
      vertical-align: middle;
    }
    .menuItem {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 15px;
      line-height: 20px;
      margin: 25px 0;
      color: white;
      &:hover {
        color: white;
      }
      &:active {
        color: white;
      }
      a {
        text-decoration: none;
      }
    }
    .active {
      background-color: white;
      color: #344767;
      padding: 10px 5px;
      width: 100%;
      border-radius: 4px 0px 0px 4px;
      &:hover {
        color: #344767;
      }
      &:active {
        color: #344767;
      }
    }
  }
}
