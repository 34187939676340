.media-card {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #F9FAFB;
	border-radius: 12px;
	padding: 12px;

	&__content {
		display: flex;
		gap: 12px;
	}

	&__image {
		width: 60px;
		height: 60px;
		border-radius: 50%;
		overflow: hidden;

		>img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__titles {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 8px;

		>h3 {
			font-size: 18px;
			font-weight: 700;
			color: #344767;
			text-transform: capitalize;
			margin: 0;
		}

		>p {
			font-size: 12px;
			color: dimgray;
			margin: 0;

			strong {
				font-weight: 700;
				color: #344767;
			}
		}
	}

	&__options {
		display: flex;
		gap: 0.6rem;

		>button {
			all: unset;
			color: inherit;
			border-radius: 50%;
			padding: 0.5rem;
			width: 30px;
			height: 30px;
			text-align: center;
			background-color: transparent;
			transition: background-color 0.3s ease-in-out;
			cursor: pointer;

			&:hover {
				color: #344767;
				background-color: rgba(#344767, 0.1);
			}

			&.delete-btn {
				color: #F44336;

				&:hover {
					color: #F44336;
					background-color: rgba(#F44336, 0.1);
				}
			}
		}
	}
}