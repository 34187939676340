@import url(../../assets/styles/_global.scss);

.nonStock {
  .editProductAction {
    width: calc(100% - 250px);
    margin: 0px 0 30px auto;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    svg {
      vertical-align: middle;
    }
    .routes {
      display: flex;
      align-items: center;
      gap: 5px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #202223;
      span {
        cursor: pointer;
      }
    }
  }
  .content {
    width: calc(100% - 280px);
    margin: 30px 15px 30px auto;
    .container {
      padding: 10px 20px;
      background-color: white;
    }
    .header {
      background-color: #eeeded;
      display: flex;
      align-items: center;
      padding: 10px;
      border-radius: 4px;
      gap: 10px;
      svg {
        vertical-align: middle;
      }
    }
    .categorySelect,
    .productDetails,
    .shippingDetails {
      display: flex;
      flex-wrap: wrap;
    }

    .textAreaContainer {
      margin: 10px 30px 10px 0;
      label {
        display: block;
        font-weight: 700;
        font-size: 12px;
      }
      textarea {
        padding: 12px 16px;
        outline: none;
        border: 1px solid #c5c6cc;
        border-radius: 8px;
        width: 100%;
      }
    }

    & > div:nth-of-type(2),
    .tagsContainer,
    .varientsContainer,
    .imagesContainer,
    .actionsContainer {
      margin-top: 30px;
    }

    .tagsContainer {
      .container {
        label {
          display: block;
          font-weight: 700;
          font-size: 12px;
        }
        & > div {
          &:first-of-type {
            padding: 12px 16px;
            outline: none;
            border: 1px solid #c5c6cc;
            border-radius: 8px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
          }
        }
        .tagContainer {
          display: flex;
          align-items: center;
          border-radius: 30px;
          background-color: #eaeaea;
          width: fit-content;
          padding: 5px 10px;
          gap: 5px;
          span {
            font-weight: 400;
            font-size: 12px;
          }
          svg {
            cursor: pointer;
            vertical-align: middle;
          }
        }
      }
    }
    .varientsContainer {
      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        & > div {
          display: flex;
          align-items: center;
          justify-content: center;
          &:nth-of-type(1) {
            gap: 5px;
          }
          &:nth-of-type(2) {
            background-color: white;
            border: 1px solid #babfc3;
            border-radius: 4px;
            padding: 8px;
            cursor: pointer;
            svg {
              vertical-align: middle;
            }
          }
        }
      }
      .container {
        .varientContainer {
          &:first-of-type {
            border: none;
          }
          border-top: 1px solid #c5c6cc;
          margin-block-start: 10px;
          & > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            gap: 10px;
            div {
              width: 100%;
            }
          }
          select,
          input {
            // width: 580px;
            width: 100%;
          }
          .imagesContainer {
            justify-content: center;
          }
        }
      }
    }

    .imagesContainer {
      .container {
        display: flex;
        align-items: center;
        justify-content: center;
        input {
          display: none;
        }
        & > div:first-of-type {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          // background-color: #1f5199;
          padding: 32px;
          border: 1px dashed rgba(#1f5199, 0.3);
          border-radius: 8px;
        }
        label {
          cursor: pointer;
          span {
            background-color: #e5effd;
            color: #1f5199;
            border-radius: 4px;
            padding: 4px 6px;
          }
        }
        span {
          margin-top: 10px;
          text-align: center;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #6d7175;
          min-width: 676px;
        }
      }
    }

    .actionsContainer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 20px;
      & > button {
        box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        padding: 12px 24px;
        cursor: pointer;
        &:first-of-type {
          background-color: #ffffff;
          border: 1px solid #babfc3;
          color: #202223;
        }
        &:last-of-type {
          background: #344767;
          border: 1px solid #babfc3;
          color: #f8f9fa;
        }
      }
    }
  }
}
