.pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination-button {
  background-color: #fff;
  // border: 1px solid #dadce0;
  color: #6c6c6c;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  padding: 10px 16px;
  margin: 0 5px;
  font-weight: 300;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.pagination-button[disabled] {
  color: #344767;
  border: 1px solid #344767;
  border-radius: 4px;
  padding: 10px 16px;
  cursor: default;
}

.pagination-button:hover {
  background-color: #f8f9fa;
}

.select-field {
  padding: 4px 12px !important;
  cursor: default !important;
  border: 1px solid #344767;
  border-radius: 4px;
  background-color: #fff;
}
