@import url(../../assets/styles/_global.scss);

.inputBoxContainer {
  margin: 10px 30px 10px 0;

  label {
    display: block;
    font-weight: 700;
    font-size: 12px;
    line-height: 2rem;
  }
  input {
    padding: 12px 16px;
    outline: none;
    border: 1px solid #c5c6cc;
    border-radius: 8px;
    width: 300px;
    line-height: 1rem;
  }
}
